@font-face {
  font-family: 'icomoon';
  src:
    url('./fonts/icomoon.woff2?v1') format('woff2'),
    url('./fonts/icomoon.woff?v1') format('woff'),
    url('./fonts/icomoon.ttf?v1') format('truetype'),
    url('./fonts/icomoon.svg?v1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-noun_bag_865857_000000:before {
  content: "\e9bf";
}
.icon-iconmonstr-shopping-cart-6:before {
  content: "\ea9b";
}
.icon-iconmonstr-shopping-cart-8:before {
  content: "\ea9c";
}
.icon-iconmonstr-shopping-cart-9:before {
  content: "\e936";
}
.icon-iconmonstr-checkout-8:before {
  content: "\e937";
}
.icon-iconmonstr-checkout-7:before {
  content: "\e938";
}
.icon-iconmonstr-shopping-cart-12:before {
  content: "\ea9d";
}
.icon-iconmonstr-checkout-10:before {
  content: "\e939";
}
.icon-iconmonstr-basket-3:before {
  content: "\ea9e";
}
.icon-iconmonstr-basket-5:before {
  content: "\ea9f";
}
.icon-bag-line:before {
  content: "\eaa0";
}
.icon-bag:before {
  content: "\eaa2";
}
.icon-iconmonstr-shopping-bag-5:before {
  content: "\eaa1";
}
.icon-iconmonstr-computer-10:before {
  content: "\eaa4";
}
.icon-iconmonstr-favorite-7:before {
  content: "\eaa5";
}
.icon-iconmonstr-favorite-8:before {
  content: "\eaa6";
}
.icon-iconmonstr-favorite-2:before {
  content: "\eaa7";
}
.icon-iconmonstr-favorite-10:before {
  content: "\eaa8";
}
.icon-iconmonstr-favorite-3:before {
  content: "\eaa9";
}
.icon-iconmonstr-favorite-9:before {
  content: "\eaaa";
}
.icon-iconmonstr-phone-8:before {
  content: "\eaab";
}
.icon-iconmonstr-phone-9:before {
  content: "\eaac";
}
.icon-iconmonstr-smartphone-3:before {
  content: "\eaad";
}
.icon-iconmonstr-smartphone-16:before {
  content: "\eaae";
}
.icon-iconmonstr-coin-2:before {
  content: "\eaaf";
}
.icon-iconmonstr-coin-3:before {
  content: "\eab0";
}
.icon-noun_419097_cc:before {
  content: "\e925";
}
.icon-iconmonstr-currency-37:before {
  content: "\e928";
}
.icon-iconmonstr-connection-7:before {
  content: "\eab4";
}
.icon-iconmonstr-compare:before {
  content: "\eab6";
}
.icon-iconmonstr-delivery-2:before {
  content: "\eab7";
}
.icon-iconmonstr-delivery-3:before {
  content: "\eab8";
}
.icon-iconmonstr-delivery-5:before {
  content: "\eab9";
}
.icon-iconmonstr-edit-10:before {
  content: "\eaba";
}
.icon-iconmonstr-layer-10:before {
  content: "\eac1";
}
.icon-iconmonstr-layer-11:before {
  content: "\eac2";
}
.icon-iconmonstr-pen-12:before {
  content: "\e92d";
}
.icon-iconmonstr-lock-6:before {
  content: "\eac4";
}
.icon-iconmonstr-lock-7:before {
  content: "\eac5";
}
.icon-iconmonstr-lock-22:before {
  content: "\eac6";
}
.icon-iconmonstr-lock-24:before {
  content: "\eac7";
}
.icon-iconmonstr-sign-in-thin:before {
  content: "\eacc";
}
.icon-iconmonstr-synchronization-3:before {
  content: "\eacd";
}
.icon-ionicons_svg_md-pricetags:before {
  content: "\eace";
}
.icon-iconmonstr-barcode-92:before {
  content: "\ead0";
}
.icon-iconmonstr-menu-6:before {
  content: "\ead4";
}
.icon-iconmonstr-task-1:before {
  content: "\ead5";
}
.icon-iconmonstr-edit-11:before {
  content: "\e90d";
}
.icon-iconmonstr-user-circle-thin:before {
  content: "\ead8";
}
.icon-iconmonstr-user-19:before {
  content: "\ead9";
}
.icon-iconmonstr-id-card-5:before {
  content: "\e912";
}
.icon-iconmonstr-user-1:before {
  content: "\eadc";
}
.icon-iconmonstr-user-8:before {
  content: "\eadd";
}
.icon-iconmonstr-building-29:before {
  content: "\eadf";
}
.icon-iconmonstr-building-3:before {
  content: "\eae1";
}
.icon-iconmonstr-home-1:before {
  content: "\eae3";
}
.icon-iconmonstr-menu-7:before {
  content: "\eae4";
}
.icon-iconmonstr-menu-11:before {
  content: "\eae6";
}
.icon-iconmonstr-menu-9:before {
  content: "\eae9";
}
.icon-iconmonstr-gear-12:before {
  content: "\e902";
}
.icon-iconmonstr-gear-2:before {
  content: "\e904";
}
.icon-iconmonstr-gear-1:before {
  content: "\e905";
}
.icon-iconmonstr-reload-panel-9:before {
  content: "\e908";
}
.icon-iconmonstr-generation-1:before {
  content: "\eabb";
}
.icon-iconmonstr-generation-2:before {
  content: "\eabc";
}
.icon-iconmonstr-generation-4:before {
  content: "\eabd";
}
.icon-iconmonstr-generation-5:before {
  content: "\eabe";
}
.icon-iconmonstr-generation-9:before {
  content: "\eac0";
}
.icon-iconmonstr-shop-7:before {
  content: "\e997";
}
.icon-iconmonstr-shop-8:before {
  content: "\e998";
}
.icon-iconmonstr-shop-6:before {
  content: "\eab1";
}
.icon-cup-trophy-win-wreath:before {
  content: "\e9b9";
}
.icon-noun_Trophy-Vector-Icon_1141816_000000:before {
  content: "\e9ba";
}
.icon-noun_brief-security_1081752_000000:before {
  content: "\e9b6";
}
.icon-noun_villa_671139_000000:before {
  content: "\e9b7";
}
.icon-noun_hamburger-menu_931910_000000:before {
  content: "\e9b4";
}
.icon-noun_menu_1540926_000000:before {
  content: "\e9b5";
}
.icon-noun_Arrow_1803897_000000:before {
  content: "\e9b0";
}
.icon-noun_Arrow_1803897_0000001:before {
  content: "\e9b1";
}
.icon-noun_lamp_979717_000000:before {
  content: "\e9b2";
}
.icon-noun_Sofa_1395351_000000:before {
  content: "\e9b3";
}
.icon-noun_quote_607800_000000:before {
  content: "\e9ae";
}
.icon-noun_quote_503188_000000:before {
  content: "\e9af";
}
.icon-noun_Discount_1921326_000000:before {
  content: "\e9ab";
}
.icon-noun_Discount_1481307_000000:before {
  content: "\e9ac";
}
.icon-noun_Percent_1122023_000000:before {
  content: "\e9ad";
}
.icon-noun-compare:before {
  content: "\e9aa";
}
.icon-noun_user_874866_000000:before {
  content: "\e9a8";
}
.icon-noun_user_874867_000000:before {
  content: "\e9a9";
}
.icon-noun_Running_1869722_000000:before {
  content: "\e9a7";
}
.icon-noun_Weight-Lifting_1715911_000000:before {
  content: "\e9a1";
}
.icon-noun_Basketball_1472339_000000:before {
  content: "\e9a2";
}
.icon-noun_Gym_1209812_000000:before {
  content: "\e9a3";
}
.icon-noun_Shopping-Cart_757317_000000:before {
  content: "\e99e";
}
.icon-cart1:before {
  content: "\e99b";
}
.icon-noun_flame_797130_000000:before {
  content: "\e99a";
}
.icon-noun_bag_1740356_000000:before {
  content: "\e933";
}
.icon-noun_compare_222822_000000:before {
  content: "\e962";
}
.icon-noun_compare_802492_000000:before {
  content: "\e976";
}
.icon-noun_heart_1780102_000000:before {
  content: "\e991";
}
.icon-noun_User_366178_000000:before {
  content: "\e90c";
}
.icon-noun_bag_958239_000000:before {
  content: "\e92b";
}
.icon-noun_right_arrow_1263681_000000:before {
  content: "\e93a";
}
.icon-noun_left_arrow_1263681_000000:before {
  content: "\e963";
}
.icon-noun_up_arrow_1263681_000000:before {
  content: "\e985";
}
.icon-noun_down_arrow_1263681_000000:before {
  content: "\e988";
}
.icon-noun_logout_1262654_000000:before {
  content: "\e98a";
}
.icon-noun_logout_1262661_000000:before {
  content: "\e98e";
}
.icon-noun_logout_1350687_000000:before {
  content: "\e990";
}
.icon-noun_edit_947732_000000:before {
  content: "\e91c";
}
.icon-noun_User_753547_000000:before {
  content: "\e967";
}
.icon-noun_user-edit_753551_000000:before {
  content: "\e978";
}
.icon-noun_delivery_873684_000000:before {
  content: "\e91e";
}
.icon-noun_mail_1707175_000000:before {
  content: "\e982";
}
.icon-noun_newspaper_blog_915757_000000:before {
  content: "\e986";
}
.icon-noun_tshirt_139540_000000:before {
  content: "\e95c";
}
.icon-noun_fashion_1066446_000000:before {
  content: "\e921";
}
.icon-noun_shopping_1891492_000000:before {
  content: "\e96a";
}
.icon-noun_close_1337415_000000:before {
  content: "\e94e";
}
.icon-noun_close_1904833_000000:before {
  content: "\e981";
}
.icon-noun_close_1905591_000000:before {
  content: "\e91a";
}
.icon-noun_Home_1165346_000000:before {
  content: "\e92c";
}
.icon-noun_call_1402767_000000:before {
  content: "\e931";
}
.icon-noun_Sync_795699_000000:before {
  content: "\e91b";
}
.icon-noun_voucher_1889481_000000:before {
  content: "\e927";
}
.icon-noun_Apple_1426921_000000:before {
  content: "\e92a";
}
.icon-noun_grid-view_312060_000000:before {
  content: "\e903";
}
.icon-noun_Shopping-Cart_760935_000000:before {
  content: "\e906";
}
.icon-noun_categories_1721776_000000:before {
  content: "\e90a";
}
.icon-noun_web-browser_1257735_000000:before {
  content: "\e916";
}
.icon-noun_web-browser_1257743_000000:before {
  content: "\e923";
}
.icon-noun_bag_1896247:before {
  content: "\e909";
}
.icon-noun_appliance_1830792:before {
  content: "\e96d";
}
.icon-noun_Sneaker_235904:before {
  content: "\e965";
}
.icon-ampersand-1:before {
  content: "\e901";
}
.icon-checkbox-checked:before {
  content: "\e917";
}
.icon-checkbox:before {
  content: "\e920";
}
.icon-edit-heart:before {
  content: "\e930";
}
.icon-checkbox-square-percent:before {
  content: "\e91f";
}
.icon-percent22:before {
  content: "\e932";
}
.icon-new:before {
  content: "\e934";
}
.icon-free:before {
  content: "\e94d";
}
.icon-iconmonstr-arrow-76:before {
  content: "\e94f";
}
.icon-iconmonstr-arrow-762:before {
  content: "\e954";
}
.icon-iconmonstr-arrow-763:before {
  content: "\e957";
}
.icon-iconmonstr-arrow-764:before {
  content: "\e959";
}
.icon-iconmonstr-credit-card-5:before {
  content: "\e95d";
}
.icon-iconmonstr-credit-card-6:before {
  content: "\e95f";
}
.icon-iconmonstr-payment-paypal-card-14:before {
  content: "\e961";
}
.icon-iconmonstr-search-10_search:before {
  content: "\e968";
}
.icon-iconmonstr-menu-4:before {
  content: "\e969";
}
.icon-iconmonstr-menu-8:before {
  content: "\e96b";
}
.icon-iconmonstr-search-thin:before {
  content: "\e96f";
}
.icon-ionicons_svg_ios-log-in:before {
  content: "\e974";
}
.icon-ionicons_svg_ios-log-out:before {
  content: "\e975";
}
.icon-ionicons_svg_md-log-in:before {
  content: "\e995";
}
.icon-ionicons_svg_md-log-out:before {
  content: "\e996";
}
.icon-sharp-corners_agree:before {
  content: "\e99c";
}
.icon-sharp-corners_bookmark_label_badge:before {
  content: "\e99d";
}
.icon-sharp-corners_give_gift:before {
  content: "\e99f";
}
.icon-sharp-corners_look:before {
  content: "\e9a0";
}
.icon-stone_marry:before {
  content: "\e9a4";
}
.icon-stone_return:before {
  content: "\e9a5";
}
.icon-user3:before {
  content: "\e9a6";
}
.icon-noun_quotes_953503_000000:before {
  content: "\e90b";
}
.icon-map_pin:before {
  content: "\e9c3";
}
.icon-stars-win:before {
  content: "\e9c2";
}
.icon-noun_star-rating_1985787_000000:before {
  content: "\e9c4";
}
.icon-themeforest:before {
  content: "\e9c0";
}
.icon-j-logo:before {
  content: "\e9c1";
}
.icon-noun_flame_690155_000000:before {
  content: "\e9bd";
}
.icon-noun_question_help_629634_000000:before {
  content: "\e9be";
}
.icon-one:before {
  content: "\e9b8";
}
.icon-two-2:before {
  content: "\e9bb";
}
.icon-three-3:before {
  content: "\e9bc";
}
.icon-iconmonstr-whatsapp-4:before {
  content: "\e979";
}
.icon-iconmonstr-whatsapp-3:before {
  content: "\e992";
}
.icon-iconmonstr-crown-1:before {
  content: "\e999";
}
.icon-iconmonstr-quote-7:before {
  content: "\e98f";
}
.icon-noun_888682-blog:before {
  content: "\e946";
}
.icon-iconmonstr-coffee-20-coffee:before {
  content: "\e987";
}
.icon-iconmonstr-rocket-20-rocket:before {
  content: "\e989";
}
.icon-iconmonstr-soccer-1-sport:before {
  content: "\e98b";
}
.icon-iconmonstr-snooker-1-sport-8:before {
  content: "\e98c";
}
.icon-iconmonstr-american-football-2-sport:before {
  content: "\e98d";
}
.icon-iconmonstr-smartphone-13-device:before {
  content: "\e907";
}
.icon-iconmonstr-retweet-3-compare:before {
  content: "\e915";
}
.icon-iconmonstr-retweet-4-compare:before {
  content: "\e926";
}
.icon-iconmonstr-handshake-1:before {
  content: "\e93d";
}
.icon-iconmonstr-handshake-10:before {
  content: "\e942";
}
.icon-iconmonstr-time-8:before {
  content: "\e943";
}
.icon-iconmonstr-school-11-backpack:before {
  content: "\e947";
}
.icon-iconmonstr-diamond-1-ring-jewel:before {
  content: "\e983";
}
.icon-iconmonstr-diamond-11-ring-jewel:before {
  content: "\e984";
}
.icon-iconmonstr-police-9:before {
  content: "\e97f";
}
.icon-iconmonstr-police-10:before {
  content: "\e980";
}
.icon-iconmonstr-delivery-8-support:before {
  content: "\e922";
}
.icon-iconmonstr-delivery-9-support:before {
  content: "\e924";
}
.icon-iconmonstr-medal-10-badge:before {
  content: "\e953";
}
.icon-iconmonstr-award-19-badge:before {
  content: "\e964";
}
.icon-iconmonstr-award-17-badge:before {
  content: "\e97c";
}
.icon-iconmonstr-award-12-badge:before {
  content: "\e97d";
}
.icon-iconmonstr-award-23-badge:before {
  content: "\e97e";
}
.icon-noun_89836_cc-grid-list:before {
  content: "\e97a";
}
.icon-noun_392251_cc:before {
  content: "\e97b";
}
.icon-iconmonstr-view-5-grid-list:before {
  content: "\e949";
}
.icon-iconmonstr-view-10-grid-list:before {
  content: "\e96e";
}
.icon-iconmonstr-view-3-grid-list:before {
  content: "\e972";
}
.icon-noun_829089_cc-appliance:before {
  content: "\e977";
}
.icon-noun_889859_cc-furniture:before {
  content: "\e966";
}
.icon-noun_971876_cc-beauty-makeup:before {
  content: "\e971";
}
.icon-noun_1615093_cc-food:before {
  content: "\e973";
}
.icon-browser:before {
  content: "\e96c";
}
.icon-arrow-browser3:before {
  content: "\e970";
}
.icon-barcode2:before {
  content: "\e960";
}
.icon-iconmonstr-id-card-8:before {
  content: "\e956";
}
.icon-iconmonstr-book-5:before {
  content: "\e958";
}
.icon-iconmonstr-coin-9:before {
  content: "\e948";
}
.icon-iconmonstr-coin-7:before {
  content: "\e952";
}
.icon-heart-edit-wish2:before {
  content: "\e955";
}
.icon-noun_1413425_cc:before {
  content: "\e951";
}
.icon-users-edit:before {
  content: "\e95a";
}
.icon-code-tracking-search:before {
  content: "\e93c";
}
.icon-iconmonstr-undo-4:before {
  content: "\e94a";
}
.icon-iconmonstr-undo-6:before {
  content: "\e94b";
}
.icon-mail2:before {
  content: "\e95b";
}
.icon-iconmonstr-email-1:before {
  content: "\e94c";
}
.icon-cards:before {
  content: "\e950";
}
.icon-cards3:before {
  content: "\e95e";
}
.icon-iconmonstr-star-6:before {
  content: "\e993";
}
.icon-iconmonstr-star-7:before {
  content: "\e994";
}
.icon-noun_1144586_cc:before {
  content: "\e93b";
}
.icon-arrow:before {
  content: "\e93e";
}
.icon-arrow2:before {
  content: "\e93f";
}
.icon-arrow_begin_first:before {
  content: "\e940";
}
.icon-arrow_end_last:before {
  content: "\e941";
}
.icon-iconmonstr-arrow-31:before {
  content: "\e944";
}
.icon-arrow4:before {
  content: "\e945";
}
.icon-navigate_before:before {
  content: "\e408";
}
.icon-navigate_next:before {
  content: "\e409";
}
.icon-arrow_back:before {
  content: "\e5c4";
}
.icon-arrow_forward:before {
  content: "\e5c8";
}
.icon-arrow_upward:before {
  content: "\e5d8";
}
.icon-arrow_downward:before {
  content: "\e5db";
}
.icon-iconmonstr-loading-1:before {
  content: "\e92e";
}
.icon-uniE92F:before {
  content: "\e92f";
}
.icon-iconmonstr-loading-3:before {
  content: "\e929";
}
.icon-iconmonstr-loading-8:before {
  content: "\e900";
}
.icon-iconmonstr-lock-31:before {
  content: "\e935";
}
.icon-iconmonstr-drop-22:before {
  content: "\e910";
}
.icon-iconmonstr-drop-7:before {
  content: "\e918";
}
.icon-noun_1058874_cc_filter:before {
  content: "\e919";
}
.icon-noun_629049_cc:before {
  content: "\e91d";
}
.icon-iconmonstr-instagram-13:before {
  content: "\e90e";
}
.icon-iconmonstr-instagram-6:before {
  content: "\e90f";
}
.icon-iconmonstr-youtube-5:before {
  content: "\e911";
}
.icon-iconmonstr-facebook-messenger-1:before {
  content: "\e913";
}
.icon-iconmonstr-facebook-4:before {
  content: "\e914";
}
.icon-ac_unit:before {
  content: "\eb3b";
}
.icon-account_balance:before {
  content: "\e84f";
}
.icon-add:before {
  content: "\e145";
}
.icon-add_alert:before {
  content: "\e003";
}
.icon-add_circle:before {
  content: "\e147";
}
.icon-control_point:before {
  content: "\e3ba";
}
.icon-add_to_queue:before {
  content: "\e05c";
}
.icon-adjust2:before {
  content: "\e39e";
}
.icon-flight:before {
  content: "\e539";
}
.icon-airplay:before {
  content: "\e055";
}
.icon-airport_shuttle:before {
  content: "\eb3c";
}
.icon-all_inclusive:before {
  content: "\eb3d";
}
.icon-all_out:before {
  content: "\eaea";
}
.icon-android2:before {
  content: "\e859";
}
.icon-arrow_drop_down:before {
  content: "\e5c5";
}
.icon-arrow_drop_up:before {
  content: "\e5c7";
}
.icon-aspect_ratio:before {
  content: "\e85b";
}
.icon-attach_money:before {
  content: "\e227";
}
.icon-autorenew:before {
  content: "\e863";
}
.icon-backspace:before {
  content: "\e14a";
}
.icon-cloud_upload:before {
  content: "\e2c3";
}
.icon-beach_access:before {
  content: "\eb3e";
}
.icon-block:before {
  content: "\e14b";
}
.icon-bluetooth2:before {
  content: "\e1a7";
}
.icon-brightness_1:before {
  content: "\e3a6";
}
.icon-brightness_2:before {
  content: "\e3a7";
}
.icon-brightness_3:before {
  content: "\e3a8";
}
.icon-brightness_low:before {
  content: "\e1ad";
}
.icon-build:before {
  content: "\e869";
}
.icon-business_center:before {
  content: "\eb3f";
}
.icon-cached:before {
  content: "\e86a";
}
.icon-cake:before {
  content: "\e7e9";
}
.icon-phone2:before {
  content: "\e0cd";
}
.icon-photo_camera:before {
  content: "\e412";
}
.icon-cancel:before {
  content: "\e5c9";
}
.icon-redeem:before {
  content: "\e8b1";
}
.icon-card_membership:before {
  content: "\e8f7";
}
.icon-card_travel:before {
  content: "\e8f8";
}
.icon-casino:before {
  content: "\eb40";
}
.icon-cast:before {
  content: "\e307";
}
.icon-cast_connected:before {
  content: "\e308";
}
.icon-center_focus_strong:before {
  content: "\e3b4";
}
.icon-center_focus_weak:before {
  content: "\e3b5";
}
.icon-change_history:before {
  content: "\e86b";
}
.icon-check_box:before {
  content: "\e834";
}
.icon-check_circle:before {
  content: "\e86c";
}
.icon-close2:before {
  content: "\e5cd";
}
.icon-wb_cloudy:before {
  content: "\e42d";
}
.icon-cloud_download:before {
  content: "\e2c0";
}
.icon-cloud_queue:before {
  content: "\e2c2";
}
.icon-code2:before {
  content: "\e86f";
}
.icon-collections_bookmark:before {
  content: "\e431";
}
.icon-palette:before {
  content: "\e40a";
}
.icon-colorize:before {
  content: "\e3b8";
}
.icon-compare:before {
  content: "\e3b9";
}
.icon-laptop2:before {
  content: "\e31e";
}
.icon-content_copy:before {
  content: "\e14d";
}
.icon-content_cut:before {
  content: "\e14e";
}
.icon-content_paste:before {
  content: "\e14f";
}
.icon-copyright2:before {
  content: "\eaeb";
}
.icon-mode_edit:before {
  content: "\e254";
}
.icon-crop2:before {
  content: "\e3be";
}
.icon-crop_free:before {
  content: "\e3c2";
}
.icon-crop_original:before {
  content: "\e3c4";
}
.icon-dashboard2:before {
  content: "\e871";
}
.icon-delete:before {
  content: "\e872";
}
.icon-delete_forever:before {
  content: "\eaec";
}
.icon-desktop_mac:before {
  content: "\e30b";
}
.icon-desktop_windows:before {
  content: "\e30c";
}
.icon-details:before {
  content: "\e3c8";
}
.icon-phonelink:before {
  content: "\e326";
}
.icon-devices_other:before {
  content: "\e337";
}
.icon-directions:before {
  content: "\e52e";
}
.icon-directions_bike:before {
  content: "\e52f";
}
.icon-directions_boat:before {
  content: "\e532";
}
.icon-directions_bus:before {
  content: "\e530";
}
.icon-directions_car:before {
  content: "\e531";
}
.icon-directions_walk:before {
  content: "\e536";
}
.icon-not_interested:before {
  content: "\e033";
}
.icon-do_not_disturb_alt:before {
  content: "\e611";
}
.icon-done:before {
  content: "\e876";
}
.icon-drafts:before {
  content: "\e151";
}
.icon-edit_location:before {
  content: "\e568";
}
.icon-eject2:before {
  content: "\e8fb";
}
.icon-markunread:before {
  content: "\e159";
}
.icon-enhanced_encryption:before {
  content: "\e63f";
}
.icon-equalizer:before {
  content: "\e01d";
}
.icon-error:before {
  content: "\e000";
}
.icon-error_outline:before {
  content: "\e001";
}
.icon-euro_symbol:before {
  content: "\eaed";
}
.icon-ev_station:before {
  content: "\e56d";
}
.icon-event_note:before {
  content: "\e616";
}
.icon-exit_to_app:before {
  content: "\e879";
}
.icon-exposure_neg_1:before {
  content: "\e3cb";
}
.icon-exposure_neg_2:before {
  content: "\e3cc";
}
.icon-exposure_plus_1:before {
  content: "\e3cd";
}
.icon-exposure_plus_2:before {
  content: "\e3ce";
}
.icon-exposure_zero:before {
  content: "\e3cf";
}
.icon-fiber_manual_record:before {
  content: "\e061";
}
.icon-fiber_new:before {
  content: "\e05e";
}
.icon-fiber_pin:before {
  content: "\e06a";
}
.icon-filter2:before {
  content: "\e3d3";
}
.icon-filter_1:before {
  content: "\e3d0";
}
.icon-filter_2:before {
  content: "\e3d1";
}
.icon-filter_3:before {
  content: "\e3d2";
}
.icon-filter_4:before {
  content: "\e3d4";
}
.icon-filter_5:before {
  content: "\e3d5";
}
.icon-filter_6:before {
  content: "\e3d6";
}
.icon-filter_7:before {
  content: "\e3d7";
}
.icon-filter_8:before {
  content: "\e3d8";
}
.icon-filter_9:before {
  content: "\e3d9";
}
.icon-filter_9_plus:before {
  content: "\e3da";
}
.icon-filter_center_focus:before {
  content: "\e3dc";
}
.icon-filter_drama:before {
  content: "\e3dd";
}
.icon-terrain:before {
  content: "\e564";
}
.icon-filter_list:before {
  content: "\e152";
}
.icon-filter_none:before {
  content: "\e3e0";
}
.icon-ruler-size-chart-noun:before {
  content: "\e3e2";
}
.icon-size-chart-ruler-noun:before {
  content: "\e3e3";
}
.icon-fingerprint:before {
  content: "\eaee";
}
.icon-fitness_center:before {
  content: "\eb43";
}
.icon-flight_land:before {
  content: "\eaef";
}
.icon-flight_takeoff:before {
  content: "\eaf0";
}
.icon-format_paint:before {
  content: "\e243";
}
.icon-free_breakfast:before {
  content: "\eb44";
}
.icon-fullscreen:before {
  content: "\e5d0";
}
.icon-fullscreen_exit:before {
  content: "\e5d1";
}
.icon-games:before {
  content: "\e021";
}
.icon-gavel2:before {
  content: "\eaf1";
}
.icon-goat:before {
  content: "\eaf2";
}
.icon-my_location:before {
  content: "\e55c";
}
.icon-location_searching:before {
  content: "\e1b7";
}
.icon-star2:before {
  content: "\e838";
}
.icon-ruler-size-chart-noun1:before {
  content: "\e310";
}
.icon-ruler-size-chart-noun2:before {
  content: "\e311";
}
.icon-healing:before {
  content: "\e3f3";
}
.icon-help:before {
  content: "\e887";
}
.icon-help_outline:before {
  content: "\e8fd";
}
.icon-highlight:before {
  content: "\e25f";
}
.icon-highlight_off:before {
  content: "\e888";
}
.icon-restore:before {
  content: "\e8b3";
}
.icon-home2:before {
  content: "\e88a";
}
.icon-hot_tub:before {
  content: "\eb46";
}
.icon-local_hotel:before {
  content: "\e549";
}
.icon-hourglass_empty:before {
  content: "\e88b";
}
.icon-hourglass_full:before {
  content: "\e88c";
}
.icon-important_devices:before {
  content: "\eaf3";
}
.icon-inbox2:before {
  content: "\e156";
}
.icon-tag_faces:before {
  content: "\e420";
}
.icon-link2:before {
  content: "\e157";
}
.icon-keyboard:before {
  content: "\e312";
}
.icon-language2:before {
  content: "\e894";
}
.icon-laptop_chromebook:before {
  content: "\e31f";
}
.icon-laptop_mac:before {
  content: "\e320";
}
.icon-laptop_windows:before {
  content: "\e321";
}
.icon-open_in_new:before {
  content: "\e89e";
}
.icon-lens:before {
  content: "\e3fa";
}
.icon-linked_camera:before {
  content: "\e438";
}
.icon-live_help:before {
  content: "\e0c6";
}
.icon-live_tv:before {
  content: "\e639";
}
.icon-local_play:before {
  content: "\e553";
}
.icon-local_airport:before {
  content: "\e53d";
}
.icon-local_atm:before {
  content: "\e53e";
}
.icon-local_bar:before {
  content: "\e540";
}
.icon-local_cafe:before {
  content: "\e541";
}
.icon-restaurant_menu:before {
  content: "\e561";
}
.icon-local_drink:before {
  content: "\e544";
}
.icon-local_florist:before {
  content: "\e545";
}
.icon-local_gas_station:before {
  content: "\e546";
}
.icon-shopping_cart:before {
  content: "\e8cc";
}
.icon-local_hospital:before {
  content: "\e548";
}
.icon-local_laundry_service:before {
  content: "\e54a";
}
.icon-local_library:before {
  content: "\e54b";
}
.icon-local_mall:before {
  content: "\e54c";
}
.icon-theaters:before {
  content: "\e8da";
}
.icon-local_offer:before {
  content: "\e54e";
}
.icon-local_parking:before {
  content: "\e54f";
}
.icon-local_pharmacy:before {
  content: "\e550";
}
.icon-local_pizza:before {
  content: "\e552";
}
.icon-print2:before {
  content: "\e8ad";
}
.icon-room:before {
  content: "\e8b4";
}
.icon-lock_open:before {
  content: "\e898";
}
.icon-lock_outline:before {
  content: "\e899";
}
.icon-looks_3:before {
  content: "\e3fb";
}
.icon-looks_4:before {
  content: "\e3fd";
}
.icon-looks_5:before {
  content: "\e3fe";
}
.icon-looks_6:before {
  content: "\e3ff";
}
.icon-looks_one-1:before {
  content: "\e400";
}
.icon-looks_two:before {
  content: "\e401";
}
.icon-sync:before {
  content: "\e627";
}
.icon-loupe:before {
  content: "\e402";
}
.icon-loyalty:before {
  content: "\e89a";
}
.icon-mail_outline:before {
  content: "\e0e1";
}
.icon-map2:before {
  content: "\e55b";
}
.icon-menu:before {
  content: "\e5d2";
}
.icon-mic:before {
  content: "\e029";
}
.icon-mic_none:before {
  content: "\e02a";
}
.icon-monetization_on:before {
  content: "\e263";
}
.icon-mood_bad:before {
  content: "\e7f3";
}
.icon-movie_creation:before {
  content: "\e404";
}
.icon-movie_filter:before {
  content: "\e43a";
}
.icon-music_note:before {
  content: "\e405";
}
.icon-music_video:before {
  content: "\e063";
}
.icon-new_releases:before {
  content: "\e031";
}
.icon-next_week:before {
  content: "\e16a";
}
.icon-nfc:before {
  content: "\e1bb";
}
.icon-person:before {
  content: "\e7fd";
}
.icon-person_add:before {
  content: "\e7fe";
}
.icon-person_pin_circle:before {
  content: "\e56a";
}
.icon-personal_video:before {
  content: "\e63b";
}
.icon-pets:before {
  content: "\eaf5";
}
.icon-phone_android:before {
  content: "\e324";
}
.icon-phone_iphone:before {
  content: "\e325";
}
.icon-phonelink_erase:before {
  content: "\e0db";
}
.icon-shopping_basket:before {
  content: "\e8cb";
}
.icon-stay_primary_portrait:before {
  content: "\e0d6";
}
.icon-smoke_free:before {
  content: "\eb4a";
}
.icon-smoking_rooms:before {
  content: "\eb4b";
}
.icon-speaker:before {
  content: "\e32d";
}
.icon-star_border:before {
  content: "\e83a";
}
.icon-star_half:before {
  content: "\e839";
}
.icon-subdirectory_arrow_left:before {
  content: "\e5d9";
}
.icon-subdirectory_arrow_right:before {
  content: "\e5da";
}
.icon-subject:before {
  content: "\e8d2";
}
.icon-tablet2:before {
  content: "\e32f";
}
.icon-tablet_android:before {
  content: "\e330";
}
.icon-tablet_mac:before {
  content: "\e331";
}
.icon-thumb_down:before {
  content: "\e8db";
}
.icon-thumb_up:before {
  content: "\e8dc";
}
.icon-grid-list-view_array:before {
  content: "\e8ea";
}
.icon-view_carousel-grid-list:before {
  content: "\e8eb";
}
.icon-view_list-grid:before {
  content: "\e8ef";
}
.icon-view_module-grid-list:before {
  content: "\e8f0";
}
.icon-view_stream-grid-list:before {
  content: "\e8f2";
}
.icon-vignette:before {
  content: "\e435";
}
.icon-visibility_off:before {
  content: "\e8f5";
}
.icon-voice_chat:before {
  content: "\e62e";
}
.icon-volume_up:before {
  content: "\e050";
}
.icon-vpn_key:before {
  content: "\e0da";
}
.icon-watch:before {
  content: "\e334";
}
.icon-watch_later:before {
  content: "\eaf6";
}
.icon-wb_incandescent:before {
  content: "\e42e";
}
.icon-wb_sunny:before {
  content: "\e430";
}
.icon-web:before {
  content: "\e051";
}
.icon-web_asset:before {
  content: "\e069";
}
.icon-weekend:before {
  content: "\e16b";
}
.icon-wifi2:before {
  content: "\e63e";
}
.icon-work:before {
  content: "\e8f9";
}
.icon-zoom_out_map:before {
  content: "\e56b";
}
.icon-alert-circle:before {
  content: "\eafa";
}
.icon-alert-octagon:before {
  content: "\eafb";
}
.icon-alert-triangle:before {
  content: "\eafc";
}
.icon-align-center2:before {
  content: "\eafd";
}
.icon-align-justify2:before {
  content: "\eafe";
}
.icon-anchor2:before {
  content: "\eaff";
}
.icon-aperture:before {
  content: "\eb00";
}
.icon-arrow-down-left:before {
  content: "\eb02";
}
.icon-arrow-down-right:before {
  content: "\eb03";
}
.icon-arrow-up-left:before {
  content: "\eb07";
}
.icon-arrow-up-right:before {
  content: "\eb08";
}
.icon-at-sign:before {
  content: "\eb09";
}
.icon-award:before {
  content: "\eb0a";
}
.icon-bar-chart2:before {
  content: "\eb0b";
}
.icon-bar-chart-2:before {
  content: "\eb0c";
}
.icon-battery2:before {
  content: "\eb0d";
}
.icon-battery-charging:before {
  content: "\eb0e";
}
.icon-bell2:before {
  content: "\eb0f";
}
.icon-bell-off:before {
  content: "\eb10";
}
.icon-bluetooth3:before {
  content: "\eb11";
}
.icon-bookmark2:before {
  content: "\eb14";
}
.icon-box:before {
  content: "\eb15";
}
.icon-briefcase2:before {
  content: "\eb16";
}
.icon-calendar2:before {
  content: "\eb17";
}
.icon-camera3:before {
  content: "\eb18";
}
.icon-camera-off:before {
  content: "\eb19";
}
.icon-cast2:before {
  content: "\eb1a";
}
.icon-check3:before {
  content: "\eb1b";
}
.icon-check-circle2:before {
  content: "\eb1c";
}
.icon-check-square2:before {
  content: "\eb1d";
}
.icon-chevrons-down:before {
  content: "\eb22";
}
.icon-chevrons-left:before {
  content: "\eb23";
}
.icon-chevrons-right:before {
  content: "\eb24";
}
.icon-chevrons-up:before {
  content: "\eb25";
}
.icon-chrome2:before {
  content: "\eb26";
}
.icon-circle2:before {
  content: "\eb27";
}
.icon-clipboard2:before {
  content: "\eb28";
}
.icon-clock:before {
  content: "\eb29";
}
.icon-cloud2:before {
  content: "\eb2a";
}
.icon-cloud-drizzle:before {
  content: "\eb2b";
}
.icon-cloud-lightning:before {
  content: "\eb2c";
}
.icon-cloud-off:before {
  content: "\eb2d";
}
.icon-cloud-rain:before {
  content: "\eb2e";
}
.icon-cloud-snow:before {
  content: "\eb2f";
}
.icon-codepen2:before {
  content: "\eb30";
}
.icon-command:before {
  content: "\eb31";
}
.icon-compass2:before {
  content: "\eb32";
}
.icon-copy2:before {
  content: "\eb33";
}
.icon-corner-down-left:before {
  content: "\eb34";
}
.icon-corner-down-right:before {
  content: "\eb35";
}
.icon-corner-left-down:before {
  content: "\eb36";
}
.icon-corner-left-up:before {
  content: "\eb37";
}
.icon-corner-right-down:before {
  content: "\eb38";
}
.icon-corner-right-up:before {
  content: "\eb39";
}
.icon-corner-up-left:before {
  content: "\eb3a";
}
.icon-corner-up-right:before {
  content: "\eb41";
}
.icon-cpu:before {
  content: "\eb42";
}
.icon-crop3:before {
  content: "\eb47";
}
.icon-crosshair:before {
  content: "\eb48";
}
.icon-delete2:before {
  content: "\eb49";
}
.icon-disc:before {
  content: "\eb4c";
}
.icon-download2:before {
  content: "\eb4d";
}
.icon-download-cloud:before {
  content: "\eb4e";
}
.icon-droplet:before {
  content: "\eb4f";
}
.icon-edit2:before {
  content: "\eb50";
}
.icon-edit-2:before {
  content: "\eb51";
}
.icon-edit-3:before {
  content: "\eb52";
}
.icon-external-link2:before {
  content: "\eb53";
}
.icon-eye2:before {
  content: "\eb54";
}
.icon-eye-off:before {
  content: "\eb55";
}
.icon-facebook2:before {
  content: "\eb56";
}
.icon-fast-forward2:before {
  content: "\eb57";
}
.icon-feather:before {
  content: "\eb58";
}
.icon-file2:before {
  content: "\eb59";
}
.icon-file-minus:before {
  content: "\eb5a";
}
.icon-file-plus:before {
  content: "\eb5b";
}
.icon-film2:before {
  content: "\eb5d";
}
.icon-flag3:before {
  content: "\eb5f";
}
.icon-folder3:before {
  content: "\eb60";
}
.icon-github2:before {
  content: "\eb61";
}
.icon-gitlab2:before {
  content: "\eb62";
}
.icon-globe2:before {
  content: "\eb63";
}
.icon-grid:before {
  content: "\eb64";
}
.icon-hash:before {
  content: "\eb65";
}
.icon-headphones2:before {
  content: "\eb66";
}
.icon-heart2:before {
  content: "\eb67";
}
.icon-help-circle:before {
  content: "\eb68";
}
.icon-home3:before {
  content: "\eb69";
}
.icon-image2:before {
  content: "\eb6a";
}
.icon-inbox3:before {
  content: "\eb6b";
}
.icon-info3:before {
  content: "\eb6c";
}
.icon-instagram2:before {
  content: "\eb6d";
}
.icon-italic2:before {
  content: "\eb6e";
}
.icon-layers2:before {
  content: "\eb6f";
}
.icon-layout:before {
  content: "\eb70";
}
.icon-life-buoy2:before {
  content: "\eb71";
}
.icon-link3:before {
  content: "\eb72";
}
.icon-link-2:before {
  content: "\eb73";
}
.icon-list3:before {
  content: "\eb74";
}
.icon-loader:before {
  content: "\eb75";
}
.icon-lock3:before {
  content: "\eb76";
}
.icon-log-in:before {
  content: "\eb77";
}
.icon-log-out:before {
  content: "\eb78";
}
.icon-mail:before {
  content: "\eb79";
}
.icon-map3:before {
  content: "\eb7a";
}
.icon-map-pin2:before {
  content: "\eb7b";
}
.icon-maximize:before {
  content: "\eb7c";
}
.icon-maximize-2:before {
  content: "\eb7d";
}
.icon-menu2:before {
  content: "\eb7e";
}
.icon-message-circle:before {
  content: "\eb7f";
}
.icon-message-square:before {
  content: "\eb80";
}
.icon-mic2:before {
  content: "\eb81";
}
.icon-mic-off:before {
  content: "\eb82";
}
.icon-minimize:before {
  content: "\eb83";
}
.icon-minimize-2:before {
  content: "\eb84";
}
.icon-minus2:before {
  content: "\eb85";
}
.icon-minus-circle2:before {
  content: "\eb86";
}
.icon-minus-square2:before {
  content: "\eb87";
}
.icon-monitor:before {
  content: "\eb88";
}
.icon-moon:before {
  content: "\eb89";
}
.icon-more-horizontal:before {
  content: "\eb8a";
}
.icon-more-vertical:before {
  content: "\eb8b";
}
.icon-move:before {
  content: "\eb8c";
}
.icon-music2:before {
  content: "\eb8d";
}
.icon-navigation2:before {
  content: "\eb8e";
}
.icon-navigation-2:before {
  content: "\eb8f";
}
.icon-octagon:before {
  content: "\eb90";
}
.icon-package:before {
  content: "\eb91";
}
.icon-paperclip2:before {
  content: "\eb92";
}
.icon-pause3:before {
  content: "\eb93";
}
.icon-pause-circle2:before {
  content: "\eb94";
}
.icon-percent2:before {
  content: "\eb95";
}
.icon-phone3:before {
  content: "\eb96";
}
.icon-phone-call:before {
  content: "\eb97";
}
.icon-phone-missed:before {
  content: "\eb9a";
}
.icon-play2:before {
  content: "\eb9e";
}
.icon-play-circle2:before {
  content: "\eb9f";
}
.icon-plus2:before {
  content: "\eba0";
}
.icon-plus-circle2:before {
  content: "\eba1";
}
.icon-plus-square2:before {
  content: "\eba2";
}
.icon-pocket2:before {
  content: "\eba3";
}
.icon-power2:before {
  content: "\eba4";
}
.icon-printer:before {
  content: "\eba5";
}
.icon-radio2:before {
  content: "\eba6";
}
.icon-refresh-ccw:before {
  content: "\eba7";
}
.icon-refresh-cw:before {
  content: "\eba8";
}
.icon-repeat3:before {
  content: "\eba9";
}
.icon-rewind:before {
  content: "\ebaa";
}
.icon-rotate-ccw:before {
  content: "\ebab";
}
.icon-rotate-cw:before {
  content: "\ebac";
}
.icon-save3:before {
  content: "\ebad";
}
.icon-scissors2:before {
  content: "\ebae";
}
.icon-search3:before {
  content: "\ebaf";
}
.icon-server2:before {
  content: "\ebb0";
}
.icon-settings2:before {
  content: "\ebb1";
}
.icon-share3:before {
  content: "\ebb2";
}
.icon-share-2:before {
  content: "\ebb3";
}
.icon-shield2:before {
  content: "\ebb4";
}
.icon-shopping-cart2:before {
  content: "\ebb5";
}
.icon-shuffle2:before {
  content: "\ebb6";
}
.icon-sidebar:before {
  content: "\ebb7";
}
.icon-slack2:before {
  content: "\ebba";
}
.icon-slash:before {
  content: "\ebbb";
}
.icon-sliders2:before {
  content: "\ebbc";
}
.icon-smartphone:before {
  content: "\ebbd";
}
.icon-speaker2:before {
  content: "\ebbe";
}
.icon-square2:before {
  content: "\ebbf";
}
.icon-star3:before {
  content: "\ebc0";
}
.icon-stop-circle2:before {
  content: "\ebc1";
}
.icon-sun:before {
  content: "\ebc2";
}
.icon-sunrise:before {
  content: "\ebc3";
}
.icon-sunset:before {
  content: "\ebc4";
}
.icon-tablet3:before {
  content: "\ebc5";
}
.icon-tag2:before {
  content: "\ebc6";
}
.icon-target:before {
  content: "\ebc7";
}
.icon-thermometer2:before {
  content: "\ebc8";
}
.icon-thumbs-down2:before {
  content: "\ebc9";
}
.icon-thumbs-up2:before {
  content: "\ebca";
}
.icon-toggle-left2:before {
  content: "\ebcb";
}
.icon-toggle-right2:before {
  content: "\ebcc";
}
.icon-trash2:before {
  content: "\ebcd";
}
.icon-trash-2:before {
  content: "\ebce";
}
.icon-trending-down:before {
  content: "\ebcf";
}
.icon-trending-up:before {
  content: "\ebd0";
}
.icon-triangle:before {
  content: "\ebd1";
}
.icon-tv3:before {
  content: "\ebd2";
}
.icon-twitter2:before {
  content: "\ebd3";
}
.icon-type:before {
  content: "\ebd4";
}
.icon-umbrella2:before {
  content: "\ebd5";
}
.icon-underline2:before {
  content: "\ebd6";
}
.icon-unlock2:before {
  content: "\ebd7";
}
.icon-upload2:before {
  content: "\ebd8";
}
.icon-upload-cloud:before {
  content: "\ebd9";
}
.icon-user2:before {
  content: "\ebda";
}
.icon-user-check:before {
  content: "\ebdb";
}
.icon-user-minus:before {
  content: "\ebdc";
}
.icon-user-plus2:before {
  content: "\ebdd";
}
.icon-user-x:before {
  content: "\ebde";
}
.icon-users2:before {
  content: "\ebdf";
}
.icon-video:before {
  content: "\ebe0";
}
.icon-video-off:before {
  content: "\ebe1";
}
.icon-voicemail2:before {
  content: "\ebe2";
}
.icon-volume-2:before {
  content: "\ebe5";
}
.icon-volume-x:before {
  content: "\ebe6";
}
.icon-watch2:before {
  content: "\ebe7";
}
.icon-wifi3:before {
  content: "\ebe8";
}
.icon-wifi-off:before {
  content: "\ebe9";
}
.icon-wind:before {
  content: "\ebea";
}
.icon-x:before {
  content: "\ebeb";
}
.icon-x-circle:before {
  content: "\ebec";
}
.icon-x-square:before {
  content: "\ebed";
}
.icon-zap:before {
  content: "\ebee";
}
.icon-zoom-in:before {
  content: "\ebef";
}
.icon-zoom-out:before {
  content: "\ebf0";
}
.icon-soundcloud:before {
  content: "\e9c5";
}
.icon-stumbleupon:before {
  content: "\e9c6";
}
.icon-whatsapp:before {
  content: "\e9c7";
}
.icon-foursquare:before {
  content: "\e9c8";
}
.icon-dropbox:before {
  content: "\e9c9";
}
.icon-skype:before {
  content: "\e9ca";
}
.icon-spotify:before {
  content: "\e9cb";
}
.icon-rss:before {
  content: "\e9cc";
}
.icon-wattpad:before {
  content: "\e9cd";
}
.icon-periscope:before {
  content: "\e9ce";
}
.icon-facebook:before {
  content: "\e9cf";
}
.icon-youtube:before {
  content: "\e9d0";
}
.icon-snapchat:before {
  content: "\e9d1";
}
.icon-flickr:before {
  content: "\e9d2";
}
.icon-xing:before {
  content: "\e9d3";
}
.icon-vk:before {
  content: "\e9d4";
}
.icon-kickstarter:before {
  content: "\e9d5";
}
.icon-behance:before {
  content: "\e9d6";
}
.icon-reddit:before {
  content: "\e9d7";
}
.icon-vimeo:before {
  content: "\e9d8";
}
.icon-pinterest:before {
  content: "\e9d9";
}
.icon-tumblr:before {
  content: "\e9da";
}
.icon-picasa:before {
  content: "\e9db";
}
.icon-twitch:before {
  content: "\e9dc";
}
.icon-path:before {
  content: "\e9dd";
}
.icon-digg:before {
  content: "\e9de";
}
.icon-github:before {
  content: "\e9df";
}
.icon-lastfm:before {
  content: "\e9e0";
}
.icon-forrst:before {
  content: "\e9e1";
}
.icon-bebo:before {
  content: "\e9e2";
}
.icon-swarm:before {
  content: "\e9e3";
}
.icon-hangout:before {
  content: "\e9e4";
}
.icon-instagram:before {
  content: "\e9e5";
}
.icon-delicious:before {
  content: "\e9e6";
}
.icon-myspace:before {
  content: "\e9e7";
}
.icon-dribbble:before {
  content: "\e9e8";
}
.icon-blogger:before {
  content: "\e9e9";
}
.icon-yelp:before {
  content: "\e9ea";
}
.icon-telegram:before {
  content: "\e9eb";
}
.icon-deviantart:before {
  content: "\e9ec";
}
.icon-twitter:before {
  content: "\e9ed";
}
.icon-yahoo:before {
  content: "\e9ee";
}
.icon-google-drive:before {
  content: "\e9ef";
}
.icon-html:before {
  content: "\e9f0";
}
.icon-vine:before {
  content: "\e9f1";
}
.icon-linkedin:before {
  content: "\e9f2";
}
.icon-team-viewer:before {
  content: "\e9f3";
}
.icon-envato:before {
  content: "\e9f4";
}
.icon-asterisk:before {
  content: "\f069";
}
.icon-plus:before {
  content: "\f067";
}
.icon-question:before {
  content: "\f128";
}
.icon-minus:before {
  content: "\f068";
}
.icon-glass:before {
  content: "\f000";
}
.icon-music:before {
  content: "\f001";
}
.icon-search:before {
  content: "\f002";
}
.icon-envelope-o:before {
  content: "\f003";
}
.icon-heart:before {
  content: "\f004";
}
.icon-star:before {
  content: "\f005";
}
.icon-star-o:before {
  content: "\f006";
}
.icon-user:before {
  content: "\f007";
}
.icon-film:before {
  content: "\f008";
}
.icon-th-large:before {
  content: "\f009";
}
.icon-th:before {
  content: "\f00a";
}
.icon-th-list:before {
  content: "\f00b";
}
.icon-check:before {
  content: "\f00c";
}
.icon-close:before {
  content: "\f00d";
}
.icon-remove:before {
  content: "\f00d";
}
.icon-times:before {
  content: "\f00d";
}
.icon-search-plus:before {
  content: "\f00e";
}
.icon-search-minus:before {
  content: "\f010";
}
.icon-power-off:before {
  content: "\f011";
}
.icon-signal2:before {
  content: "\f012";
}
.icon-cog:before {
  content: "\f013";
}
.icon-gear:before {
  content: "\f013";
}
.icon-trash-o:before {
  content: "\f014";
}
.icon-home:before {
  content: "\f015";
}
.icon-file-o:before {
  content: "\f016";
}
.icon-clock-o:before {
  content: "\f017";
}
.icon-road:before {
  content: "\f018";
}
.icon-download:before {
  content: "\f019";
}
.icon-arrow-circle-o-down:before {
  content: "\f01a";
}
.icon-arrow-circle-o-up:before {
  content: "\f01b";
}
.icon-inbox:before {
  content: "\f01c";
}
.icon-play-circle-o:before {
  content: "\f01d";
}
.icon-repeat:before {
  content: "\f01e";
}
.icon-rotate-right:before {
  content: "\f01e";
}
.icon-refresh:before {
  content: "\f021";
}
.icon-list-alt:before {
  content: "\f022";
}
.icon-lock:before {
  content: "\f023";
}
.icon-flag:before {
  content: "\f024";
}
.icon-headphones:before {
  content: "\f025";
}
.icon-volume-off:before {
  content: "\f026";
}
.icon-volume-down:before {
  content: "\f027";
}
.icon-volume-up:before {
  content: "\f028";
}
.icon-qrcode:before {
  content: "\f029";
}
.icon-barcode:before {
  content: "\f02a";
}
.icon-tag:before {
  content: "\f02b";
}
.icon-tags:before {
  content: "\f02c";
}
.icon-book:before {
  content: "\f02d";
}
.icon-bookmark:before {
  content: "\f02e";
}
.icon-print:before {
  content: "\f02f";
}
.icon-camera:before {
  content: "\f030";
}
.icon-font:before {
  content: "\f031";
}
.icon-bold:before {
  content: "\f032";
}
.icon-italic:before {
  content: "\f033";
}
.icon-text-height:before {
  content: "\f034";
}
.icon-text-width:before {
  content: "\f035";
}
.icon-align-left:before {
  content: "\f036";
}
.icon-align-center:before {
  content: "\f037";
}
.icon-align-right:before {
  content: "\f038";
}
.icon-align-justify:before {
  content: "\f039";
}
.icon-list:before {
  content: "\f03a";
}
.icon-dedent:before {
  content: "\f03b";
}
.icon-outdent:before {
  content: "\f03b";
}
.icon-indent:before {
  content: "\f03c";
}
.icon-video-camera:before {
  content: "\f03d";
}
.icon-image:before {
  content: "\f03e";
}
.icon-photo:before {
  content: "\f03e";
}
.icon-picture-o:before {
  content: "\f03e";
}
.icon-pencil:before {
  content: "\f040";
}
.icon-map-marker:before {
  content: "\f041";
}
.icon-adjust:before {
  content: "\f042";
}
.icon-tint:before {
  content: "\f043";
}
.icon-edit:before {
  content: "\f044";
}
.icon-pencil-square-o:before {
  content: "\f044";
}
.icon-share-square-o:before {
  content: "\f045";
}
.icon-check-square-o:before {
  content: "\f046";
}
.icon-arrows:before {
  content: "\f047";
}
.icon-step-backward:before {
  content: "\f048";
}
.icon-fast-backward:before {
  content: "\f049";
}
.icon-backward:before {
  content: "\f04a";
}
.icon-play:before {
  content: "\f04b";
}
.icon-pause:before {
  content: "\f04c";
}
.icon-stop:before {
  content: "\f04d";
}
.icon-forward:before {
  content: "\f04e";
}
.icon-fast-forward:before {
  content: "\f050";
}
.icon-step-forward:before {
  content: "\f051";
}
.icon-eject:before {
  content: "\f052";
}
.icon-chevron-left:before {
  content: "\f053";
}
.icon-chevron-right:before {
  content: "\f054";
}
.icon-plus-circle:before {
  content: "\f055";
}
.icon-minus-circle:before {
  content: "\f056";
}
.icon-times-circle:before {
  content: "\f057";
}
.icon-check-circle:before {
  content: "\f058";
}
.icon-question-circle:before {
  content: "\f059";
}
.icon-info-circle:before {
  content: "\f05a";
}
.icon-crosshairs:before {
  content: "\f05b";
}
.icon-times-circle-o:before {
  content: "\f05c";
}
.icon-check-circle-o:before {
  content: "\f05d";
}
.icon-ban:before {
  content: "\f05e";
}
.icon-arrow-left:before {
  content: "\f060";
}
.icon-arrow-right:before {
  content: "\f061";
}
.icon-arrow-up:before {
  content: "\f062";
}
.icon-arrow-down:before {
  content: "\f063";
}
.icon-mail-forward:before {
  content: "\f064";
}
.icon-share:before {
  content: "\f064";
}
.icon-expand:before {
  content: "\f065";
}
.icon-compress:before {
  content: "\f066";
}
.icon-exclamation-circle:before {
  content: "\f06a";
}
.icon-gift:before {
  content: "\f06b";
}
.icon-leaf:before {
  content: "\f06c";
}
.icon-fire:before {
  content: "\f06d";
}
.icon-eye:before {
  content: "\f06e";
}
.icon-eye-slash:before {
  content: "\f070";
}
.icon-exclamation-triangle:before {
  content: "\f071";
}
.icon-warning:before {
  content: "\f071";
}
.icon-plane:before {
  content: "\f072";
}
.icon-calendar:before {
  content: "\f073";
}
.icon-random:before {
  content: "\f074";
}
.icon-comment:before {
  content: "\f075";
}
.icon-magnet:before {
  content: "\f076";
}
.icon-chevron-up:before {
  content: "\f077";
}
.icon-chevron-down:before {
  content: "\f078";
}
.icon-retweet:before {
  content: "\f079";
}
.icon-shopping-cart:before {
  content: "\f07a";
}
.icon-folder:before {
  content: "\f07b";
}
.icon-folder-open:before {
  content: "\f07c";
}
.icon-arrows-v:before {
  content: "\f07d";
}
.icon-arrows-h:before {
  content: "\f07e";
}
.icon-bar-chart:before {
  content: "\f080";
}
.icon-bar-chart-o:before {
  content: "\f080";
}
.icon-twitter-square:before {
  content: "\f081";
}
.icon-facebook-square:before {
  content: "\f082";
}
.icon-camera-retro:before {
  content: "\f083";
}
.icon-key:before {
  content: "\f084";
}
.icon-cogs:before {
  content: "\f085";
}
.icon-gears:before {
  content: "\f085";
}
.icon-comments:before {
  content: "\f086";
}
.icon-thumbs-o-up:before {
  content: "\f087";
}
.icon-thumbs-o-down:before {
  content: "\f088";
}
.icon-star-half:before {
  content: "\f089";
}
.icon-heart-o:before {
  content: "\f08a";
}
.icon-sign-out:before {
  content: "\f08b";
}
.icon-linkedin-square:before {
  content: "\f08c";
}
.icon-thumb-tack:before {
  content: "\f08d";
}
.icon-external-link:before {
  content: "\f08e";
}
.icon-sign-in:before {
  content: "\f090";
}
.icon-trophy_win_cup:before {
  content: "\f091";
}
.icon-github-square:before {
  content: "\f092";
}
.icon-upload:before {
  content: "\f093";
}
.icon-lemon-o:before {
  content: "\f094";
}
.icon-phone:before {
  content: "\f095";
}
.icon-square-o:before {
  content: "\f096";
}
.icon-bookmark-o:before {
  content: "\f097";
}
.icon-phone-square:before {
  content: "\f098";
}
.icon-twitter3:before {
  content: "\f099";
}
.icon-facebook3:before {
  content: "\f09a";
}
.icon-facebook-f:before {
  content: "\f09a";
}
.icon-github3:before {
  content: "\f09b";
}
.icon-unlock:before {
  content: "\f09c";
}
.icon-credit-card:before {
  content: "\f09d";
}
.icon-feed:before {
  content: "\f09e";
}
.icon-rss2:before {
  content: "\f09e";
}
.icon-hdd-o:before {
  content: "\f0a0";
}
.icon-bullhorn:before {
  content: "\f0a1";
}
.icon-bell-o:before {
  content: "\f0a2";
}
.icon-certificate:before {
  content: "\f0a3";
}
.icon-hand-o-right:before {
  content: "\f0a4";
}
.icon-hand-o-left:before {
  content: "\f0a5";
}
.icon-hand-o-up:before {
  content: "\f0a6";
}
.icon-hand-o-down:before {
  content: "\f0a7";
}
.icon-arrow-circle-left:before {
  content: "\f0a8";
}
.icon-arrow-circle-right:before {
  content: "\f0a9";
}
.icon-arrow-circle-up:before {
  content: "\f0aa";
}
.icon-arrow-circle-down:before {
  content: "\f0ab";
}
.icon-wrench:before {
  content: "\f0ad";
}
.icon-tasks:before {
  content: "\f0ae";
}
.icon-filter:before {
  content: "\f0b0";
}
.icon-briefcase:before {
  content: "\f0b1";
}
.icon-arrows-alt:before {
  content: "\f0b2";
}
.icon-group:before {
  content: "\f0c0";
}
.icon-users:before {
  content: "\f0c0";
}
.icon-chain:before {
  content: "\f0c1";
}
.icon-link:before {
  content: "\f0c1";
}
.icon-cloud:before {
  content: "\f0c2";
}
.icon-flask:before {
  content: "\f0c3";
}
.icon-cut:before {
  content: "\f0c4";
}
.icon-scissors:before {
  content: "\f0c4";
}
.icon-copy:before {
  content: "\f0c5";
}
.icon-files-o:before {
  content: "\f0c5";
}
.icon-paperclip:before {
  content: "\f0c6";
}
.icon-floppy-o:before {
  content: "\f0c7";
}
.icon-save:before {
  content: "\f0c7";
}
.icon-square:before {
  content: "\f0c8";
}
.icon-bars:before {
  content: "\f0c9";
}
.icon-navicon:before {
  content: "\f0c9";
}
.icon-reorder:before {
  content: "\f0c9";
}
.icon-list-ul:before {
  content: "\f0ca";
}
.icon-list-ol:before {
  content: "\f0cb";
}
.icon-strikethrough:before {
  content: "\f0cc";
}
.icon-underline:before {
  content: "\f0cd";
}
.icon-table:before {
  content: "\f0ce";
}
.icon-magic:before {
  content: "\f0d0";
}
.icon-truck:before {
  content: "\f0d1";
}
.icon-pinterest2:before {
  content: "\f0d2";
}
.icon-pinterest-square:before {
  content: "\f0d3";
}
.icon-google-plus-square:before {
  content: "\f0d4";
}
.icon-google-plus:before {
  content: "\f0d5";
}
.icon-money:before {
  content: "\f0d6";
}
.icon-caret-down:before {
  content: "\f0d7";
}
.icon-caret-up:before {
  content: "\f0d8";
}
.icon-caret-left:before {
  content: "\f0d9";
}
.icon-caret-right:before {
  content: "\f0da";
}
.icon-columns:before {
  content: "\f0db";
}
.icon-sort:before {
  content: "\f0dc";
}
.icon-unsorted:before {
  content: "\f0dc";
}
.icon-sort-desc:before {
  content: "\f0dd";
}
.icon-sort-down:before {
  content: "\f0dd";
}
.icon-sort-asc:before {
  content: "\f0de";
}
.icon-sort-up:before {
  content: "\f0de";
}
.icon-envelope:before {
  content: "\f0e0";
}
.icon-linkedin2:before {
  content: "\f0e1";
}
.icon-rotate-left:before {
  content: "\f0e2";
}
.icon-undo:before {
  content: "\f0e2";
}
.icon-gavel:before {
  content: "\f0e3";
}
.icon-legal:before {
  content: "\f0e3";
}
.icon-dashboard:before {
  content: "\f0e4";
}
.icon-tachometer:before {
  content: "\f0e4";
}
.icon-comment-o:before {
  content: "\f0e5";
}
.icon-comments-o:before {
  content: "\f0e6";
}
.icon-bolt:before {
  content: "\f0e7";
}
.icon-flash:before {
  content: "\f0e7";
}
.icon-sitemap:before {
  content: "\f0e8";
}
.icon-umbrella:before {
  content: "\f0e9";
}
.icon-clipboard:before {
  content: "\f0ea";
}
.icon-paste:before {
  content: "\f0ea";
}
.icon-lightbulb-o:before {
  content: "\f0eb";
}
.icon-exchange:before {
  content: "\f0ec";
}
.icon-cloud-download:before {
  content: "\f0ed";
}
.icon-cloud-upload:before {
  content: "\f0ee";
}
.icon-user-md:before {
  content: "\f0f0";
}
.icon-stethoscope:before {
  content: "\f0f1";
}
.icon-suitcase:before {
  content: "\f0f2";
}
.icon-bell:before {
  content: "\f0f3";
}
.icon-coffee:before {
  content: "\f0f4";
}
.icon-cutlery:before {
  content: "\f0f5";
}
.icon-file-text-o:before {
  content: "\f0f6";
}
.icon-building-o:before {
  content: "\f0f7";
}
.icon-hospital-o:before {
  content: "\f0f8";
}
.icon-ambulance:before {
  content: "\f0f9";
}
.icon-medkit:before {
  content: "\f0fa";
}
.icon-fighter-jet:before {
  content: "\f0fb";
}
.icon-beer:before {
  content: "\f0fc";
}
.icon-h-square:before {
  content: "\f0fd";
}
.icon-plus-square:before {
  content: "\f0fe";
}
.icon-angle-double-left:before {
  content: "\f100";
}
.icon-angle-double-right:before {
  content: "\f101";
}
.icon-angle-double-up:before {
  content: "\f102";
}
.icon-angle-double-down:before {
  content: "\f103";
}
.icon-angle-left:before {
  content: "\f104";
}
.icon-angle-right:before {
  content: "\f105";
}
.icon-angle-up:before {
  content: "\f106";
}
.icon-angle-down:before {
  content: "\f107";
}
.icon-desktop:before {
  content: "\f108";
}
.icon-laptop:before {
  content: "\f109";
}
.icon-tablet:before {
  content: "\f10a";
}
.icon-mobile:before {
  content: "\f10b";
}
.icon-mobile-phone:before {
  content: "\f10b";
}
.icon-circle-o:before {
  content: "\f10c";
}
.icon-quote-left:before {
  content: "\f10d";
}
.icon-quote-right:before {
  content: "\f10e";
}
.icon-spinner:before {
  content: "\f110";
}
.icon-circle:before {
  content: "\f111";
}
.icon-mail-reply:before {
  content: "\f112";
}
.icon-reply:before {
  content: "\f112";
}
.icon-github-alt:before {
  content: "\f113";
}
.icon-folder-o:before {
  content: "\f114";
}
.icon-folder-open-o:before {
  content: "\f115";
}
.icon-smile-o:before {
  content: "\f118";
}
.icon-frown-o:before {
  content: "\f119";
}
.icon-meh-o:before {
  content: "\f11a";
}
.icon-gamepad:before {
  content: "\f11b";
}
.icon-keyboard-o:before {
  content: "\f11c";
}
.icon-flag-o:before {
  content: "\f11d";
}
.icon-flag-checkered:before {
  content: "\f11e";
}
.icon-terminal:before {
  content: "\f120";
}
.icon-code:before {
  content: "\f121";
}
.icon-mail-reply-all:before {
  content: "\f122";
}
.icon-reply-all:before {
  content: "\f122";
}
.icon-star-half-empty:before {
  content: "\f123";
}
.icon-star-half-full:before {
  content: "\f123";
}
.icon-star-half-o:before {
  content: "\f123";
}
.icon-location-arrow:before {
  content: "\f124";
}
.icon-crop:before {
  content: "\f125";
}
.icon-code-fork:before {
  content: "\f126";
}
.icon-chain-broken:before {
  content: "\f127";
}
.icon-unlink:before {
  content: "\f127";
}
.icon-info:before {
  content: "\f129";
}
.icon-exclamation:before {
  content: "\f12a";
}
.icon-superscript:before {
  content: "\f12b";
}
.icon-subscript:before {
  content: "\f12c";
}
.icon-eraser:before {
  content: "\f12d";
}
.icon-puzzle-piece:before {
  content: "\f12e";
}
.icon-microphone:before {
  content: "\f130";
}
.icon-microphone-slash:before {
  content: "\f131";
}
.icon-shield:before {
  content: "\f132";
}
.icon-calendar-o:before {
  content: "\f133";
}
.icon-fire-extinguisher:before {
  content: "\f134";
}
.icon-rocket:before {
  content: "\f135";
}
.icon-maxcdn:before {
  content: "\f136";
}
.icon-chevron-circle-left:before {
  content: "\f137";
}
.icon-chevron-circle-right:before {
  content: "\f138";
}
.icon-chevron-circle-up:before {
  content: "\f139";
}
.icon-chevron-circle-down:before {
  content: "\f13a";
}
.icon-html52:before {
  content: "\f13b";
}
.icon-css32:before {
  content: "\f13c";
}
.icon-anchor:before {
  content: "\f13d";
}
.icon-unlock-alt:before {
  content: "\f13e";
}
.icon-bullseye:before {
  content: "\f140";
}
.icon-ellipsis-h:before {
  content: "\f141";
}
.icon-ellipsis-v:before {
  content: "\f142";
}
.icon-rss-square:before {
  content: "\f143";
}
.icon-play-circle:before {
  content: "\f144";
}
.icon-ticket:before {
  content: "\f145";
}
.icon-minus-square:before {
  content: "\f146";
}
.icon-minus-square-o:before {
  content: "\f147";
}
.icon-level-up:before {
  content: "\f148";
}
.icon-level-down:before {
  content: "\f149";
}
.icon-check-square:before {
  content: "\f14a";
}
.icon-pencil-square:before {
  content: "\f14b";
}
.icon-external-link-square:before {
  content: "\f14c";
}
.icon-share-square:before {
  content: "\f14d";
}
.icon-compass:before {
  content: "\f14e";
}
.icon-caret-square-o-down:before {
  content: "\f150";
}
.icon-toggle-down:before {
  content: "\f150";
}
.icon-caret-square-o-up:before {
  content: "\f151";
}
.icon-toggle-up:before {
  content: "\f151";
}
.icon-caret-square-o-right:before {
  content: "\f152";
}
.icon-toggle-right:before {
  content: "\f152";
}
.icon-eur:before {
  content: "\f153";
}
.icon-euro:before {
  content: "\f153";
}
.icon-gbp:before {
  content: "\f154";
}
.icon-dollar:before {
  content: "\f155";
}
.icon-usd:before {
  content: "\f155";
}
.icon-inr:before {
  content: "\f156";
}
.icon-rupee:before {
  content: "\f156";
}
.icon-cny:before {
  content: "\f157";
}
.icon-jpy:before {
  content: "\f157";
}
.icon-rmb:before {
  content: "\f157";
}
.icon-yen:before {
  content: "\f157";
}
.icon-rouble:before {
  content: "\f158";
}
.icon-rub:before {
  content: "\f158";
}
.icon-ruble:before {
  content: "\f158";
}
.icon-krw:before {
  content: "\f159";
}
.icon-won:before {
  content: "\f159";
}
.icon-bitcoin2:before {
  content: "\f15a";
}
.icon-btc:before {
  content: "\f15a";
}
.icon-file:before {
  content: "\f15b";
}
.icon-file-text:before {
  content: "\f15c";
}
.icon-sort-alpha-asc:before {
  content: "\f15d";
}
.icon-sort-alpha-desc:before {
  content: "\f15e";
}
.icon-sort-amount-asc:before {
  content: "\f160";
}
.icon-sort-amount-desc:before {
  content: "\f161";
}
.icon-sort-numeric-asc:before {
  content: "\f162";
}
.icon-sort-numeric-desc:before {
  content: "\f163";
}
.icon-thumbs-up:before {
  content: "\f164";
}
.icon-thumbs-down:before {
  content: "\f165";
}
.icon-youtube-square:before {
  content: "\f166";
}
.icon-youtube2:before {
  content: "\f167";
}
.icon-xing2:before {
  content: "\f168";
}
.icon-xing-square:before {
  content: "\f169";
}
.icon-youtube-play:before {
  content: "\f16a";
}
.icon-dropbox2:before {
  content: "\f16b";
}
.icon-stack-overflow:before {
  content: "\f16c";
}
.icon-instagram3:before {
  content: "\f16d";
}
.icon-flickr2:before {
  content: "\f16e";
}
.icon-adn:before {
  content: "\f170";
}
.icon-bitbucket2:before {
  content: "\f171";
}
.icon-bitbucket-square:before {
  content: "\f172";
}
.icon-tumblr2:before {
  content: "\f173";
}
.icon-tumblr-square:before {
  content: "\f174";
}
.icon-long-arrow-down:before {
  content: "\f175";
}
.icon-long-arrow-up:before {
  content: "\f176";
}
.icon-long-arrow-left:before {
  content: "\f177";
}
.icon-long-arrow-right:before {
  content: "\f178";
}
.icon-apple2:before {
  content: "\f179";
}
.icon-windows2:before {
  content: "\f17a";
}
.icon-android3:before {
  content: "\f17b";
}
.icon-dribbble2:before {
  content: "\f17d";
}
.icon-skype2:before {
  content: "\f17e";
}
.icon-foursquare2:before {
  content: "\f180";
}
.icon-trello2:before {
  content: "\f181";
}
.icon-female:before {
  content: "\f182";
}
.icon-male:before {
  content: "\f183";
}
.icon-gittip:before {
  content: "\f184";
}
.icon-gratipay:before {
  content: "\f184";
}
.icon-sun-o:before {
  content: "\f185";
}
.icon-moon-o:before {
  content: "\f186";
}
.icon-archive:before {
  content: "\f187";
}
.icon-bug:before {
  content: "\f188";
}
.icon-vk2:before {
  content: "\f189";
}
.icon-weibo:before {
  content: "\f18a";
}
.icon-renren:before {
  content: "\f18b";
}
.icon-pagelines:before {
  content: "\f18c";
}
.icon-stack-exchange:before {
  content: "\f18d";
}
.icon-arrow-circle-o-right:before {
  content: "\f18e";
}
.icon-arrow-circle-o-left:before {
  content: "\f190";
}
.icon-caret-square-o-left:before {
  content: "\f191";
}
.icon-toggle-left:before {
  content: "\f191";
}
.icon-dot-circle-o:before {
  content: "\f192";
}
.icon-wheelchair:before {
  content: "\f193";
}
.icon-vimeo-square:before {
  content: "\f194";
}
.icon-try:before {
  content: "\f195";
}
.icon-turkish-lira:before {
  content: "\f195";
}
.icon-plus-square-o:before {
  content: "\f196";
}
.icon-space-shuttle:before {
  content: "\f197";
}
.icon-slack3:before {
  content: "\f198";
}
.icon-envelope-square:before {
  content: "\f199";
}
.icon-wordpress2:before {
  content: "\f19a";
}
.icon-openid:before {
  content: "\f19b";
}
.icon-bank:before {
  content: "\f19c";
}
.icon-institution:before {
  content: "\f19c";
}
.icon-university:before {
  content: "\f19c";
}
.icon-graduation-cap:before {
  content: "\f19d";
}
.icon-mortar-board:before {
  content: "\f19d";
}
.icon-yahoo2:before {
  content: "\f19e";
}
.icon-google2:before {
  content: "\f1a0";
}
.icon-reddit2:before {
  content: "\f1a1";
}
.icon-reddit-square:before {
  content: "\f1a2";
}
.icon-stumbleupon-circle:before {
  content: "\f1a3";
}
.icon-stumbleupon2:before {
  content: "\f1a4";
}
.icon-delicious2:before {
  content: "\f1a5";
}
.icon-digg2:before {
  content: "\f1a6";
}
.icon-pied-piper-pp:before {
  content: "\f1a7";
}
.icon-pied-piper-alt:before {
  content: "\f1a8";
}
.icon-drupal2:before {
  content: "\f1a9";
}
.icon-joomla2:before {
  content: "\f1aa";
}
.icon-language:before {
  content: "\f1ab";
}
.icon-fax:before {
  content: "\f1ac";
}
.icon-building:before {
  content: "\f1ad";
}
.icon-child:before {
  content: "\f1ae";
}
.icon-paw:before {
  content: "\f1b0";
}
.icon-spoon:before {
  content: "\f1b1";
}
.icon-cube:before {
  content: "\f1b2";
}
.icon-cubes:before {
  content: "\f1b3";
}
.icon-behance2:before {
  content: "\f1b4";
}
.icon-behance-square:before {
  content: "\f1b5";
}
.icon-steam2:before {
  content: "\f1b6";
}
.icon-steam-square:before {
  content: "\f1b7";
}
.icon-recycle:before {
  content: "\f1b8";
}
.icon-automobile:before {
  content: "\f1b9";
}
.icon-car:before {
  content: "\f1b9";
}
.icon-cab:before {
  content: "\f1ba";
}
.icon-taxi:before {
  content: "\f1ba";
}
.icon-tree:before {
  content: "\f1bb";
}
.icon-spotify2:before {
  content: "\f1bc";
}
.icon-deviantart2:before {
  content: "\f1bd";
}
.icon-soundcloud2:before {
  content: "\f1be";
}
.icon-database:before {
  content: "\f1c0";
}
.icon-file-pdf-o:before {
  content: "\f1c1";
}
.icon-file-word-o:before {
  content: "\f1c2";
}
.icon-file-excel-o:before {
  content: "\f1c3";
}
.icon-file-powerpoint-o:before {
  content: "\f1c4";
}
.icon-file-image-o:before {
  content: "\f1c5";
}
.icon-file-photo-o:before {
  content: "\f1c5";
}
.icon-file-picture-o:before {
  content: "\f1c5";
}
.icon-file-archive-o:before {
  content: "\f1c6";
}
.icon-file-zip-o:before {
  content: "\f1c6";
}
.icon-file-audio-o:before {
  content: "\f1c7";
}
.icon-file-sound-o:before {
  content: "\f1c7";
}
.icon-file-movie-o:before {
  content: "\f1c8";
}
.icon-file-video-o:before {
  content: "\f1c8";
}
.icon-file-code-o:before {
  content: "\f1c9";
}
.icon-vine2:before {
  content: "\f1ca";
}
.icon-codepen3:before {
  content: "\f1cb";
}
.icon-jsfiddle2:before {
  content: "\f1cc";
}
.icon-life-bouy:before {
  content: "\f1cd";
}
.icon-life-buoy:before {
  content: "\f1cd";
}
.icon-life-ring:before {
  content: "\f1cd";
}
.icon-life-saver:before {
  content: "\f1cd";
}
.icon-support:before {
  content: "\f1cd";
}
.icon-circle-o-notch:before {
  content: "\f1ce";
}
.icon-ra:before {
  content: "\f1d0";
}
.icon-rebel:before {
  content: "\f1d0";
}
.icon-resistance:before {
  content: "\f1d0";
}
.icon-empire:before {
  content: "\f1d1";
}
.icon-ge:before {
  content: "\f1d1";
}
.icon-git-square:before {
  content: "\f1d2";
}
.icon-git2:before {
  content: "\f1d3";
}
.icon-hacker-news:before {
  content: "\f1d4";
}
.icon-y-combinator-square:before {
  content: "\f1d4";
}
.icon-yc-square:before {
  content: "\f1d4";
}
.icon-tencent-weibo:before {
  content: "\f1d5";
}
.icon-qq:before {
  content: "\f1d6";
}
.icon-wechat2:before {
  content: "\f1d7";
}
.icon-weixin:before {
  content: "\f1d7";
}
.icon-paper-plane:before {
  content: "\f1d8";
}
.icon-send:before {
  content: "\f1d8";
}
.icon-paper-plane-o:before {
  content: "\f1d9";
}
.icon-send-o:before {
  content: "\f1d9";
}
.icon-history:before {
  content: "\f1da";
}
.icon-circle-thin:before {
  content: "\f1db";
}
.icon-header:before {
  content: "\f1dc";
}
.icon-paragraph:before {
  content: "\f1dd";
}
.icon-sliders:before {
  content: "\f1de";
}
.icon-share-alt:before {
  content: "\f1e0";
}
.icon-share-alt-square:before {
  content: "\f1e1";
}
.icon-bomb:before {
  content: "\f1e2";
}
.icon-futbol-o:before {
  content: "\f1e3";
}
.icon-soccer-ball-o:before {
  content: "\f1e3";
}
.icon-tty:before {
  content: "\f1e4";
}
.icon-binoculars:before {
  content: "\f1e5";
}
.icon-plug:before {
  content: "\f1e6";
}
.icon-slideshare:before {
  content: "\f1e7";
}
.icon-twitch2:before {
  content: "\f1e8";
}
.icon-yelp2:before {
  content: "\f1e9";
}
.icon-newspaper-o:before {
  content: "\f1ea";
}
.icon-wifi:before {
  content: "\f1eb";
}
.icon-calculator:before {
  content: "\f1ec";
}
.icon-paypal2:before {
  content: "\f1ed";
}
.icon-google-wallet:before {
  content: "\f1ee";
}
.icon-cc-visa:before {
  content: "\f1f0";
}
.icon-cc-mastercard:before {
  content: "\f1f1";
}
.icon-cc-discover:before {
  content: "\f1f2";
}
.icon-cc-amex:before {
  content: "\f1f3";
}
.icon-cc-paypal:before {
  content: "\f1f4";
}
.icon-cc-stripe:before {
  content: "\f1f5";
}
.icon-bell-slash:before {
  content: "\f1f6";
}
.icon-bell-slash-o:before {
  content: "\f1f7";
}
.icon-trash:before {
  content: "\f1f8";
}
.icon-copyright:before {
  content: "\f1f9";
}
.icon-at:before {
  content: "\f1fa";
}
.icon-eyedropper:before {
  content: "\f1fb";
}
.icon-paint-brush:before {
  content: "\f1fc";
}
.icon-birthday-cake:before {
  content: "\f1fd";
}
.icon-area-chart:before {
  content: "\f1fe";
}
.icon-pie-chart:before {
  content: "\f200";
}
.icon-line-chart:before {
  content: "\f201";
}
.icon-lastfm2:before {
  content: "\f202";
}
.icon-lastfm-square:before {
  content: "\f203";
}
.icon-toggle-off:before {
  content: "\f204";
}
.icon-toggle-on:before {
  content: "\f205";
}
.icon-bicycle:before {
  content: "\f206";
}
.icon-bus:before {
  content: "\f207";
}
.icon-ioxhost:before {
  content: "\f208";
}
.icon-angellist2:before {
  content: "\f209";
}
.icon-cc:before {
  content: "\f20a";
}
.icon-ils:before {
  content: "\f20b";
}
.icon-shekel:before {
  content: "\f20b";
}
.icon-sheqel:before {
  content: "\f20b";
}
.icon-meanpath:before {
  content: "\f20c";
}
.icon-buysellads:before {
  content: "\f20d";
}
.icon-connectdevelop:before {
  content: "\f20e";
}
.icon-dashcube:before {
  content: "\f210";
}
.icon-forumbee:before {
  content: "\f211";
}
.icon-leanpub:before {
  content: "\f212";
}
.icon-sellsy:before {
  content: "\f213";
}
.icon-shirtsinbulk:before {
  content: "\f214";
}
.icon-simplybuilt:before {
  content: "\f215";
}
.icon-skyatlas:before {
  content: "\f216";
}
.icon-cart-plus:before {
  content: "\f217";
}
.icon-cart-arrow-down:before {
  content: "\f218";
}
.icon-diamond:before {
  content: "\f219";
}
.icon-ship:before {
  content: "\f21a";
}
.icon-user-secret:before {
  content: "\f21b";
}
.icon-motorcycle:before {
  content: "\f21c";
}
.icon-street-view:before {
  content: "\f21d";
}
.icon-heartbeat:before {
  content: "\f21e";
}
.icon-venus:before {
  content: "\f221";
}
.icon-mars:before {
  content: "\f222";
}
.icon-mercury:before {
  content: "\f223";
}
.icon-intersex:before {
  content: "\f224";
}
.icon-transgender:before {
  content: "\f224";
}
.icon-transgender-alt:before {
  content: "\f225";
}
.icon-venus-double:before {
  content: "\f226";
}
.icon-mars-double:before {
  content: "\f227";
}
.icon-venus-mars:before {
  content: "\f228";
}
.icon-mars-stroke:before {
  content: "\f229";
}
.icon-mars-stroke-v:before {
  content: "\f22a";
}
.icon-mars-stroke-h:before {
  content: "\f22b";
}
.icon-neuter:before {
  content: "\f22c";
}
.icon-genderless:before {
  content: "\f22d";
}
.icon-facebook-official:before {
  content: "\f230";
}
.icon-pinterest-p:before {
  content: "\f231";
}
.icon-whatsapp2:before {
  content: "\f232";
}
.icon-server:before {
  content: "\f233";
}
.icon-user-plus:before {
  content: "\f234";
}
.icon-user-times:before {
  content: "\f235";
}
.icon-bed:before {
  content: "\f236";
}
.icon-hotel:before {
  content: "\f236";
}
.icon-viacoin:before {
  content: "\f237";
}
.icon-train:before {
  content: "\f238";
}
.icon-subway:before {
  content: "\f239";
}
.icon-medium2:before {
  content: "\f23a";
}
.icon-y-combinator:before {
  content: "\f23b";
}
.icon-yc:before {
  content: "\f23b";
}
.icon-opencart:before {
  content: "\f23d";
}
.icon-expeditedssl:before {
  content: "\f23e";
}
.icon-battery:before {
  content: "\f240";
}
.icon-battery-4:before {
  content: "\f240";
}
.icon-battery-full:before {
  content: "\f240";
}
.icon-battery-3:before {
  content: "\f241";
}
.icon-battery-three-quarters:before {
  content: "\f241";
}
.icon-battery-2:before {
  content: "\f242";
}
.icon-battery-half:before {
  content: "\f242";
}
.icon-battery-1:before {
  content: "\f243";
}
.icon-battery-quarter:before {
  content: "\f243";
}
.icon-battery-0:before {
  content: "\f244";
}
.icon-battery-empty:before {
  content: "\f244";
}
.icon-mouse-pointer:before {
  content: "\f245";
}
.icon-i-cursor:before {
  content: "\f246";
}
.icon-object-group:before {
  content: "\f247";
}
.icon-object-ungroup:before {
  content: "\f248";
}
.icon-sticky-note:before {
  content: "\f249";
}
.icon-sticky-note-o:before {
  content: "\f24a";
}
.icon-cc-jcb:before {
  content: "\f24b";
}
.icon-cc-diners-club:before {
  content: "\f24c";
}
.icon-clone:before {
  content: "\f24d";
}
.icon-balance-scale:before {
  content: "\f24e";
}
.icon-hourglass-o:before {
  content: "\f250";
}
.icon-hourglass-1:before {
  content: "\f251";
}
.icon-hourglass-start:before {
  content: "\f251";
}
.icon-hourglass-2:before {
  content: "\f252";
}
.icon-hourglass-half:before {
  content: "\f252";
}
.icon-hourglass-3:before {
  content: "\f253";
}
.icon-hourglass-end:before {
  content: "\f253";
}
.icon-hourglass:before {
  content: "\f254";
}
.icon-hand-grab-o:before {
  content: "\f255";
}
.icon-hand-rock-o:before {
  content: "\f255";
}
.icon-hand-paper-o:before {
  content: "\f256";
}
.icon-hand-stop-o:before {
  content: "\f256";
}
.icon-hand-scissors-o:before {
  content: "\f257";
}
.icon-hand-lizard-o:before {
  content: "\f258";
}
.icon-hand-spock-o:before {
  content: "\f259";
}
.icon-hand-pointer-o:before {
  content: "\f25a";
}
.icon-hand-peace-o:before {
  content: "\f25b";
}
.icon-trademark:before {
  content: "\f25c";
}
.icon-registered:before {
  content: "\f25d";
}
.icon-creative-commons:before {
  content: "\f25e";
}
.icon-gg:before {
  content: "\f260";
}
.icon-gg-circle:before {
  content: "\f261";
}
.icon-tripadvisor2:before {
  content: "\f262";
}
.icon-odnoklassniki2:before {
  content: "\f263";
}
.icon-odnoklassniki-square:before {
  content: "\f264";
}
.icon-get-pocket:before {
  content: "\f265";
}
.icon-wikipedia-w:before {
  content: "\f266";
}
.icon-safari:before {
  content: "\f267";
}
.icon-chrome:before {
  content: "\f268";
}
.icon-firefox:before {
  content: "\f269";
}
.icon-opera2:before {
  content: "\f26a";
}
.icon-internet-explorer:before {
  content: "\f26b";
}
.icon-television:before {
  content: "\f26c";
}
.icon-tv:before {
  content: "\f26c";
}
.icon-contao:before {
  content: "\f26d";
}
.icon-px2:before {
  content: "\f26e";
}
.icon-amazon2:before {
  content: "\f270";
}
.icon-calendar-plus-o:before {
  content: "\f271";
}
.icon-calendar-minus-o:before {
  content: "\f272";
}
.icon-calendar-times-o:before {
  content: "\f273";
}
.icon-calendar-check-o:before {
  content: "\f274";
}
.icon-industry:before {
  content: "\f275";
}
.icon-map-pin:before {
  content: "\f276";
}
.icon-map-signs:before {
  content: "\f277";
}
.icon-map-o:before {
  content: "\f278";
}
.icon-map:before {
  content: "\f279";
}
.icon-commenting:before {
  content: "\f27a";
}
.icon-commenting-o:before {
  content: "\f27b";
}
.icon-houzz2:before {
  content: "\f27c";
}
.icon-vimeo2:before {
  content: "\f27d";
}
.icon-black-tie:before {
  content: "\f27e";
}
.icon-fonticons:before {
  content: "\f280";
}
.icon-reddit-alien:before {
  content: "\f281";
}
.icon-edge:before {
  content: "\f282";
}
.icon-credit-card-alt:before {
  content: "\f283";
}
.icon-codiepie:before {
  content: "\f284";
}
.icon-modx:before {
  content: "\f285";
}
.icon-fort-awesome:before {
  content: "\f286";
}
.icon-usb:before {
  content: "\f287";
}
.icon-product-hunt:before {
  content: "\f288";
}
.icon-mixcloud2:before {
  content: "\f289";
}
.icon-scribd2:before {
  content: "\f28a";
}
.icon-pause-circle:before {
  content: "\f28b";
}
.icon-pause-circle-o:before {
  content: "\f28c";
}
.icon-stop-circle:before {
  content: "\f28d";
}
.icon-stop-circle-o:before {
  content: "\f28e";
}
.icon-shopping-bag:before {
  content: "\f290";
}
.icon-shopping-basket:before {
  content: "\f291";
}
.icon-hashtag:before {
  content: "\f292";
}
.icon-bluetooth:before {
  content: "\f293";
}
.icon-bluetooth-b:before {
  content: "\f294";
}
.icon-percent:before {
  content: "\f295";
}
.icon-gitlab3:before {
  content: "\f296";
}
.icon-wpbeginner:before {
  content: "\f297";
}
.icon-wpforms:before {
  content: "\f298";
}
.icon-envira:before {
  content: "\f299";
}
.icon-universal-access:before {
  content: "\f29a";
}
.icon-wheelchair-alt:before {
  content: "\f29b";
}
.icon-question-circle-o:before {
  content: "\f29c";
}
.icon-blind:before {
  content: "\f29d";
}
.icon-audio-description:before {
  content: "\f29e";
}
.icon-volume-control-phone:before {
  content: "\f2a0";
}
.icon-braille:before {
  content: "\f2a1";
}
.icon-assistive-listening-systems:before {
  content: "\f2a2";
}
.icon-american-sign-language-interpreting:before {
  content: "\f2a3";
}
.icon-asl-interpreting:before {
  content: "\f2a3";
}
.icon-deaf:before {
  content: "\f2a4";
}
.icon-deafness:before {
  content: "\f2a4";
}
.icon-hard-of-hearing:before {
  content: "\f2a4";
}
.icon-glide:before {
  content: "\f2a5";
}
.icon-glide-g:before {
  content: "\f2a6";
}
.icon-sign-language:before {
  content: "\f2a7";
}
.icon-signing:before {
  content: "\f2a7";
}
.icon-low-vision:before {
  content: "\f2a8";
}
.icon-viadeo2:before {
  content: "\f2a9";
}
.icon-viadeo-square:before {
  content: "\f2aa";
}
.icon-snapchat2:before {
  content: "\f2ab";
}
.icon-snapchat-ghost:before {
  content: "\f2ac";
}
.icon-snapchat-square:before {
  content: "\f2ad";
}
.icon-pied-piper:before {
  content: "\f2ae";
}
.icon-first-order:before {
  content: "\f2b0";
}
.icon-yoast:before {
  content: "\f2b1";
}
.icon-themeisle:before {
  content: "\f2b2";
}
.icon-google-plus-circle:before {
  content: "\f2b3";
}
.icon-google-plus-official:before {
  content: "\f2b3";
}
.icon-fa:before {
  content: "\f2b4";
}
.icon-font-awesome:before {
  content: "\f2b4";
}
.icon-handshake-o:before {
  content: "\f2b5";
}
.icon-envelope-open:before {
  content: "\f2b6";
}
.icon-envelope-open-o:before {
  content: "\f2b7";
}
.icon-linode:before {
  content: "\f2b8";
}
.icon-address-book:before {
  content: "\f2b9";
}
.icon-address-book-o:before {
  content: "\f2ba";
}
.icon-address-card:before {
  content: "\f2bb";
}
.icon-vcard:before {
  content: "\f2bb";
}
.icon-address-card-o:before {
  content: "\f2bc";
}
.icon-vcard-o:before {
  content: "\f2bc";
}
.icon-user-circle:before {
  content: "\f2bd";
}
.icon-user-circle-o:before {
  content: "\f2be";
}
.icon-user-o:before {
  content: "\f2c0";
}
.icon-id-badge:before {
  content: "\f2c1";
}
.icon-drivers-license:before {
  content: "\f2c2";
}
.icon-id-card:before {
  content: "\f2c2";
}
.icon-drivers-license-o:before {
  content: "\f2c3";
}
.icon-id-card-o:before {
  content: "\f2c3";
}
.icon-quora2:before {
  content: "\f2c4";
}
.icon-free-code-camp:before {
  content: "\f2c5";
}
.icon-telegram2:before {
  content: "\f2c6";
}
.icon-thermometer:before {
  content: "\f2c7";
}
.icon-thermometer-4:before {
  content: "\f2c7";
}
.icon-thermometer-full:before {
  content: "\f2c7";
}
.icon-thermometer-3:before {
  content: "\f2c8";
}
.icon-thermometer-three-quarters:before {
  content: "\f2c8";
}
.icon-thermometer-2:before {
  content: "\f2c9";
}
.icon-thermometer-half:before {
  content: "\f2c9";
}
.icon-thermometer-1:before {
  content: "\f2ca";
}
.icon-thermometer-quarter:before {
  content: "\f2ca";
}
.icon-thermometer-0:before {
  content: "\f2cb";
}
.icon-thermometer-empty:before {
  content: "\f2cb";
}
.icon-shower:before {
  content: "\f2cc";
}
.icon-bath:before {
  content: "\f2cd";
}
.icon-bathtub:before {
  content: "\f2cd";
}
.icon-s15:before {
  content: "\f2cd";
}
.icon-podcast:before {
  content: "\f2ce";
}
.icon-window-maximize:before {
  content: "\f2d0";
}
.icon-window-minimize:before {
  content: "\f2d1";
}
.icon-window-restore:before {
  content: "\f2d2";
}
.icon-times-rectangle:before {
  content: "\f2d3";
}
.icon-window-close:before {
  content: "\f2d3";
}
.icon-times-rectangle-o:before {
  content: "\f2d4";
}
.icon-window-close-o:before {
  content: "\f2d4";
}
.icon-bandcamp2:before {
  content: "\f2d5";
}
.icon-grav2:before {
  content: "\f2d6";
}
.icon-etsy2:before {
  content: "\f2d7";
}
.icon-imdb2:before {
  content: "\f2d8";
}
.icon-ravelry:before {
  content: "\f2d9";
}
.icon-eercast:before {
  content: "\f2da";
}
.icon-microchip:before {
  content: "\f2db";
}
.icon-snowflake-o:before {
  content: "\f2dc";
}
.icon-superpowers:before {
  content: "\f2dd";
}
.icon-wpexplorer:before {
  content: "\f2de";
}
.icon-meetup2:before {
  content: "\f2e0";
}
